import App from './App';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import {Icon} from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './app.scss';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import Store from './redux/store';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Moment from 'moment';

ReactDOM.render(
    <Provider store={Store}>
        <Router>
            <Route path="/" component={App}/>
        </Router>
    </Provider>,
    document.getElementById('root'));

const loc = 'en'
// Un-comment to select user's language automatically
// loc = (navigator.language || navigator.userLanguage).split('-')[0] || 'en'
Moment.locale(loc)

// this part resolve an issue where the markers would not appear
// @ts-ignore
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
