import {HIP_DB, SOLO_DB, TWIN_DB} from '../firebase';
import {SweObj} from '../models/swe.model';
import {Solo} from '../models/solo.model';
import {Unregistered} from '../models/star-package.model';
import {SkyModel} from '../models/skymodel.model';
import {Twin} from '../models/twin.model';
import Moment from 'moment';

export class DDDate extends Date {
	getJD() {
		return (this.getTime() / 86400000) + 2440587.5;
	}

	setJD(jd: number) {
		return this.setTime((jd - 2440587.5) * 86400000);
	}

	getMJD() {
		return this.getJD() - 2400000.5;
	}

	setMJD(mjd: number) {
		return this.setJD(mjd + 2400000.5);
	}

}

class SWHelper {
	circumpolarMask                           = undefined;
	$stel: any                                = null;
	$lastSelectionSweObj: SweObj | null       = null;
	$lastSelectionCustomSweObj: SweObj | null = null;
	$selectionLayer: any                      = null;
	$observingLayer: any                      = null;
	$skyHintsLayer: any                       = null;
	lastWikiQuery: SkyModel | null            = null;
	lastWikiData: any                         = null;
	layerObjectCache: SweObj[]                = [];
	HIP_CACHE: { [key: string]: SkyModel }    = {};
	monthNames                                = ['January', 'February', 'March', 'April', 'May', 'June',
		'July', 'August', 'September', 'October', 'November', 'December'
	];

	isSolo(val: any): val is Solo {
		return val?.starName && !val.starName2;
	}

	isTwin(val: any): val is Twin {
		return val?.starName2;
	}

	isUnregistered(val: any): val is Unregistered {
		return val && !this.isSolo(val) && !this.isTwin(val);
	}

	astroConstants = {
		// Light time for 1 au in s
		ERFA_AULT: 499.004782,
		// Seconds per day
		ERFA_DAYSEC: 86400.0,
		// Days per Julian year
		ERFA_DJY: 365.25,
		// Astronomical unit in m
		ERFA_DAU: 149597870000
	};

	async getSoloFromTwin(twin: Twin, twinNumber = 0): Promise<Solo> {
		let obj: Partial<Solo>;
		if (twinNumber) {
			obj = {
				starName: twin.starName2, HIP: twin.HIP2, constellation:
				twin.constellation2, declination:
				twin.declination2,
				rightAscension: twin.rightAscension2,
				dateOfRegistration: twin.dateOfRegistration,
				referenceNumber: twin.referenceNumber,
				personalMessage: twin.personalMessage2,
				starDedicatedTo: '',
				isTwin: true
			};
		} else {
			obj = {
				starName: twin.starName, HIP: twin.HIP, constellation:
				twin.constellation, declination: twin.declination,
				rightAscension: twin.rightAscension,
				referenceNumber: twin.referenceNumber,
				personalMessage: twin.personalMessage,
				dateOfRegistration: twin.dateOfRegistration,
				starDedicatedTo: '',
				isTwin: true
			};
		}
		return this.attachHIPtoStar(obj as Solo);
	}

	async selectTwin(twin: Twin, props: { selection: any, setSelectedPackage: any }): Promise<void> {
		const viewing: Solo = await this.getSoloFromTwin(twin, twin.viewing && (twin.viewing.starName === twin.starName) ? 1 : 0);
		return this.selectSolo(viewing, (v: number) => props.setSelectedPackage({...twin, v, viewing}));
	}

	selectSolo(ss: Solo, beforeSelect: (v: number) => void): void {
		if (!ss) {
			return;
		}
		const skyModel    = ss;
		const formattedID = skyModel?.starName.replace(/[^\p{L}\p{N}\p{Z}]/gu, '').replace('  ', ' ');
		if (skyModel.starName) {
			skyModel.id    = formattedID;
			skyModel.names = [formattedID].concat(...(skyModel.names || []));
		}
		let obj: SweObj = this.soloToSweObj(skyModel)!;
		if (!obj) {
			const stelObj = this.$stel.createObj(skyModel.model, skyModel);
			obj           = {...stelObj, ...skyModel};
			this.$selectionLayer.add(obj);
			this.$lastSelectionCustomSweObj = obj;
		}
		if (!obj) {
			console.info('Can\'t find object in SWE: ' + skyModel?.names?.[0]);
			return;
		}
		beforeSelect(obj.v);
		obj = {...obj, ...ss};
		// try {
		// 	this.setTime(new Date(Date.parse(ss.dateOfRegistration)));
		// } catch (err) {
		// 	console.warn('Couldn\'t set time', err);
		// }
		this.setSweObjAsSelection(obj);
	}

	iconForSkySourceTypes(skySourceTypes: string[]) {
		// Array sorted by specificity, i.e. the most generic names at the end
		const iconForType = {
			// Stars
			'Pec?': 'star',
			'**?': 'double_star',
			'**': 'double_star',
			'V*': 'constiable_star',
			'V*?': 'constiable_star',
			'*': 'star',

			// Candidates
			'As?': 'group_of_stars',
			'SC?': 'group_of_galaxies',
			'Gr?': 'group_of_galaxies',
			'C?G': 'group_of_galaxies',
			'G?': 'galaxy',

			// Multiple objects
			reg: 'region_defined_in_the_sky',
			SCG: 'group_of_galaxies',
			ClG: 'group_of_galaxies',
			GrG: 'group_of_galaxies',
			IG: 'interacting_galaxy',
			PaG: 'pair_of_galaxies',
			'C?*': 'open_galactic_cluster',
			'Gl?': 'globular_cluster',
			GlC: 'globular_cluster',
			OpC: 'open_galactic_cluster',
			'Cl*': 'open_galactic_cluster',
			'As*': 'group_of_stars',
			mul: 'multiple_objects',

			// Interstellar matter
			'PN?': 'planetary_nebula',
			PN: 'planetary_nebula',
			SNR: 'planetary_nebula',
			'SR?': 'planetary_nebula',
			ISM: 'interstellar_matter',

			// Galaxies
			PoG: 'part_of_galaxy',
			QSO: 'quasar',
			G: 'galaxy',

			dso: 'deep_sky',

			// Solar System
			Asa: 'artificial_satellite',
			Moo: 'moon',
			Sun: 'sun',
			Pla: 'planet',
			DPl: 'planet',
			Com: 'comet',
			MPl: 'minor_planet',
			SSO: 'minor_planet',

			Con: 'constellation'
		}
		for (const i in skySourceTypes) {
			if (skySourceTypes[i] in iconForType) {
				// @ts-ignore
				const icon = iconForType[skySourceTypes[i]];
				return 'images/svg/target_types/' + icon + '.svg'
			}
		}
		return 'images/svg/target_types/unknown.svg'
	}

	iconForSkySource(skySource: SkyModel) {
		return this.iconForSkySourceTypes(skySource?.types || [])
	}

	cleanupOneSkySourceName(name: any, flags?: number | undefined) {
		flags = flags || 4
		return this.$stel.designationCleanup(name, flags)
	}

	formatPhase(v: number) {
		return (v * 100).toFixed(0) + '%'
	}

	formatMagnitude(v: number) {
		if (!v) {
			return 'Unknown'
		}
		return v.toFixed(2)
	}

	formatDistance(d: number) {
		// d is in AU
		if (!d) {
			return 'NAN';
		}
		const ly = d * this.astroConstants.ERFA_AULT / this.astroConstants.ERFA_DAYSEC / this.astroConstants.ERFA_DJY
		if (ly >= 0.1) {
			return ly.toFixed(2) + '<span class="radecUnit">&nbsp;light years</span>'
		}
		if (d >= 0.1) {
			return d.toFixed(2) + '<span class="radecUnit">&nbsp;AU</span>'
		}
		const meter = d * this.astroConstants.ERFA_DAU;
		if (meter >= 1000) {
			return (meter / 1000).toFixed(2) + '<span class="radecUnit">&nbsp;km</span>'
		}
		return meter.toFixed(2) + '<span class="radecUnit">&nbsp;m</span>'
	}

	computeVisibility(obj: any, props: { stel: any }): string {
		const vis = obj.computeVisibility()
		let str   = ''
		if (vis.length === 0) {
			str = 'Not visible tonight';
		} else if (vis[0].rise === null) {
			str = 'Always visible tonight';
		} else {
			str = `Rise: ${this.formatTime(vis[0].rise, props)}&nbsp;&nbsp;&nbsp; Set: ${this.formatTime(vis[0].set, props)}`;
		}
		return str;
	}

	getLocalTime() {
		if (!this.$stel?.core?.observer) {
			return Moment(new Date());
		}
		const d = new DDDate();
		d.setMJD(this.$stel.core.observer.utc);
		const m = Moment(d);
		m.local();
		return m;
	}

	setTime(m: Date): void {
		const moment = Moment(m);
		moment.local();
		moment.milliseconds(this.getLocalTime().milliseconds());
		this.$stel.core.observer.utc = new DDDate(moment.toDate()).getMJD();
	}

	formatTime(jdm: number, props: { stel: any }): string {
		const d = new DDDate();
		// @ts-ignore
		d.setMJD(jdm);
		const utc = Moment(d);
		utc.utcOffset(props.stel.utcoffset)
		return utc.format('HH:mm')
	}

	nameForSkySource(skySource: { names: any[]; }) {
		if (!skySource || !skySource.names) {
			return '?'
		}
		return this.cleanupOneSkySourceName(skySource.names[0])
	}

	culturalNameToList(cn: { name_native: string; name_pronounce: string; user_prefer_native: any; name_translated: any; }) {
		const res = []

		const formatNative = (_cn: { name_native: string; name_pronounce: string; user_prefer_native: any; name_translated: any; }) => {
			if (cn.name_native && cn.name_pronounce) {
				return cn.name_native + ', <i>' + cn.name_pronounce + '</i>'
			}
			if (cn.name_native) {
				return cn.name_native
			}
			if (cn.name_pronounce) {
				return cn.name_pronounce
			}
		}

		const nativeName = formatNative(cn)
		if (cn.user_prefer_native && nativeName) {
			res.push(nativeName)
		}
		if (cn.name_translated) {
			res.push(cn.name_translated)
		}
		if (!cn.user_prefer_native && nativeName) {
			res.push(nativeName)
		}
		return res;
	}

	namesForSkySource(ss: { names: any[]; culturalNames: { [x: string]: { name_native: string; name_pronounce: string; user_prefer_native: any; name_translated: any; }; }; }, flags: number) {
		// Return a list of cleaned up names
		if (!ss || !ss.names) {
			return []
		}
		if (!flags) flags = 10
		let res: any[] = []
		if (ss.culturalNames) {
			for (const i in ss.culturalNames) {
				res = res.concat(this.culturalNameToList(ss.culturalNames[i]))
			}
		}
		if (!this.$stel) {
			return res;
		}
		res = res.concat(ss.names.map(n => this.$stel.designationCleanup(n, flags)))
		// Remove duplicates, this can happen between * and V* catalogs
		res = res.filter((v, i) => {
			return res.indexOf(v) === i
		})
		res = res.filter((v, i) => {
			return !v.startsWith('CON ')
		})
		return res
	}

	nameForSkySourceType(otype: any) {
		if (!this.$stel) {
			return 'Unknown Type';
		}
		const res = this.$stel.otypeToStr(otype)
		return res || 'Unknown Type'
	}

	nameForGalaxyMorpho(morpho: string) {
		const galTab = {
			E: 'Elliptical',
			SB: 'Barred Spiral',
			SAB: 'Intermediate Spiral',
			SA: 'Spiral',
			S0: 'Lenticular',
			S: 'Spiral',
			Im: 'Irregular',
			dSph: 'Dwarf Spheroidal',
			dE: 'Dwarf Elliptical'
		}
		for (const morp in galTab) {
			if (morpho.startsWith(morp)) {
				// @ts-ignore
				return galTab[morp]
			}
		}
		return '';
	}

	getShareLink(selectedObject: Solo) {
		const q = `?ref=${selectedObject.referenceNumber}`;
		return `https://wsr-starfinder.com/${q}`;
	}

	// Return a SweObj matching a passed sky source JSON object if it's already instanciated in SWE
	soloToSweObj(ss: Solo): SweObj | null {
		if (!ss || !ss.model) {
			return null;
		}
		let obj;
		if (ss.model === 'tle_satellite') {
			const id = 'NORAD ' + ss.model_data.norad_number;
			obj      = this.$stel.getObj(id);
		} else if (ss.model === 'constellation' && ss.model_data.iau_abbreviation) {
			const id = 'CON western ' + ss.model_data.iau_abbreviation;
			obj      = this.$stel.getObj(id);
		}
		if (!obj) {
			obj = this.$stel.getObj(ss.names?.[0]);
		}
		if (!obj && ss.names?.[0].startsWith('Gaia DR2 ')) {
			const gname = ss.names[0].replace(/^Gaia DR2 /, 'GAIA ');
			obj         = this.$stel.getObj(gname);
		}
		return obj;
	}

	createPersonalPara(messages: string[]): string {
		messages = messages?.filter(m => !!m);
		if (!messages?.length) {
			return '';
		}
		return messages?.length > 1 ? messages.join('\n') : messages[0];
	}

	async attachHIPtoStar(star: Solo): Promise<Solo> {
		this.HIP_CACHE[star.HIP] = this.HIP_CACHE[star.HIP] ||
			await HIP_DB.doc(star.HIP.toString()).get().then(res => ({...res.data()} as Solo));
		return {
			...this.HIP_CACHE[star.HIP], ...star
		};
	}

	async getPackageByRef(ref: number | undefined | false): Promise<Solo | Twin | null> {
		if (!ref) {
			return null;
		}
		const solo = await this.getSoloByRef(ref);
		if (solo) {
			return solo;
		}
		const twin = await TWIN_DB.doc(ref.toString()).get();
		const data = twin?.data() as Twin;
		return (data ? {...data} : null) as Twin;
	}

	async getSoloByRef(ref: number): Promise<Solo | null> {
		const starQ = await SOLO_DB.doc(ref.toString()).get();
		if (starQ.exists) {
			const star = starQ.data() as Solo;
			return this.attachHIPtoStar(star);
		}
		return null;
	}

	async getPackageFromSelection(obj: SweObj | (SweObj & Solo), ref?: number | false | undefined): Promise<Solo | Twin | null> {
		if (ref) {
			return null;
		}
		const names = obj.designations();
		if (!names || !names.length) {
			throw new Error('Can\'t find object without names')
		}

		// Several artifical satellites share the same common name, so we use
		// the unambiguous NORAD number instead
		for (const j in names) {
			if (names[j].startsWith('NORAD ')) {
				const tmpName = names[0]
				names[0]      = names[j]
				names[j]      = tmpName
			}
		}
		const ss = obj.jsonData
		if (!ss.model_data) {
			ss.model_data = {}
		}
		// Names fixup
		let i;
		for (i in ss.names) {
			if (ss.names[i].startsWith('GAIA')) {
				ss.names[i] = ss.names[i].replace(/^GAIA /, 'Gaia DR2 ')
			}
		}
		ss.culturalNames = obj.culturalDesignations();
		const HIP        = this.getHIPFromNames(ss.names);
		if (HIP) {
			const skyPackage: Solo | Twin | null = await this.getPackageByRef(ref);
			if (!skyPackage) {
				return ss;
			}
			if (this.isSolo(skyPackage)) {
				const hip = await this.attachHIPtoStar(skyPackage!);
				return {...hip, ...skyPackage};
			}
			return {...skyPackage} as Twin;
		}
		return ss
	}

	getHIPFromNames(names: string[]): string | undefined {
		return names?.find(n => n.includes('HIP'))?.replace('HIP ', '');
	}

	setSweObjAsSelection(obj: SweObj) {
		this.$stel.core.selection = obj;
		this.$stel.pointAndLock(obj);
		this.zoomIn();
	}

	zoomIn() {
		this.$stel.zoomTo(this.calcFOVfromDeg(23));
	}

	calcFOVfromDeg(deg: number): number {
		return deg * Math.PI / 180;
	}

	// Get data for a SkySource from wikipedia
	async getSkySourceSummaryFromWikipedia(ss: SkyModel): Promise<string> {
		if (this.lastWikiData && (ss === this.lastWikiQuery)) {
			return this.lastWikiData;
		}
		this.lastWikiQuery = ss;
		let title;
		if (ss.model === 'jpl_sso') {
			title = this.cleanupOneSkySourceName(ss.names[0]).toLowerCase()
			if (['mercury', 'venus', 'earth', 'mars', 'jupiter', 'saturn', 'neptune', 'pluto'].indexOf(title) > -1) {
				title = title + '_(planet)'
			}
			if (ss.types[0] === 'Moo') {
				title = title + '_(moon)'
			}
		}
		if (ss.model === 'mpc_asteroid') {
			title = this.cleanupOneSkySourceName(ss.names[0]).toLowerCase()
		}
		if (ss.model === 'constellation') {
			title = this.cleanupOneSkySourceName(ss.names[0]).toLowerCase() + '_(constellation)'
		}
		if (ss.model === 'dso') {
			for (const i in ss.names) {
				if (ss.names[i].startsWith('M ')) {
					title = 'Messier_' + ss.names[i].substr(2)
					break
				}
				if (ss.names[i].startsWith('NGC ')) {
					title = ss.names[i]
					break
				}
				if (ss.names[i].startsWith('IC ')) {
					title = ss.names[i]
					break
				}
			}
		}
		if (ss.model === 'star') {
			for (const i in ss.names) {
				if (ss.names[i].startsWith('* ')) {
					title = this.cleanupOneSkySourceName(ss.names[i])
					break
				}
			}
		}
		if (!title) {
			return '';
		}
		const data        = await fetch('https://en.wikipedia.org/w/api.php?action=query&redirects&prop=extracts&exintro&exlimit=1&exchars=300&format=json&origin=*&titles=' + title,
			{headers: {'Content-Type': 'application/json; charset=UTF-8'}})
			.then(response => {
				return response.json()
			}).catch(console.warn);
		this.lastWikiData = data;
		return data;
	}

	getGeolocation() {
		console.log('Getting geolocalization');

		// First get geoIP location, to use as fallback
		return fetch('https://freegeoip.stellarium.org/json/')
			.then(async (location: Response) => {
				const loc                                           = await location.json();
				const pos: { lng: any; accuracy: number; lat: any } = {
					lat: loc.latitude,
					lng: loc.longitude,
					accuracy: 20000
				};
				console.log('GeoIP localization: ' + JSON.stringify(pos))
				return pos;
			}, err => {
				console.log(err);
			}).then(geoipPos => {
				if (navigator.geolocation) {
					return new Promise((resolve, reject) => {
						navigator.geolocation.getCurrentPosition((position) => {
							const pos = {
								lat: position.coords.latitude,
								lng: position.coords.longitude,
								accuracy: position.coords.accuracy
							}
							resolve(pos);
						}, () => {
							console.log('Could not get location from browser, use fallback from GeoIP')
							// No HTML5 Geolocalization support, return geoip fallback values
							if (geoipPos) {
								resolve(geoipPos);
							} else {
								reject(new Error('Cannot detect position'));
							}
						}, {enableHighAccuracy: true})
					});
				}
				return geoipPos;
			})
	}

	delay(t: number, v: any) {
		return new Promise((resolve) => {
			setTimeout(resolve.bind(null, v), t)
		})
	}

	geoCodePosition(pos: { lat: string | number; lng: string | number; accuracy: number; alt: any; }) {
		console.log('Geocoding position... ')
		const ll  = `Lat ${(+pos.lat).toFixed(3)}° Lon ${(+pos.lng).toFixed(3)}°`;
		const loc = {
			short_name: pos.accuracy > 500 ? `Near ${ll}` : ll,
			country: 'Unknown',
			lng: pos.lng,
			lat: pos.lat,
			alt: pos.alt ? pos.alt : 0,
			accuracy: pos.accuracy,
			street_address: ''
		}
		return fetch('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' + pos.lat + '&lon=' + pos.lng,
			{headers: {}}).then(response => {
			if (response.ok) {
				return response.json().then(res => {
					const city     = res.address.city ? res.address.city : (res.address.village ? res.address.village : res.name)
					loc.short_name = pos.accuracy > 500 ? `Near ${city}` : city
					loc.country    = res.address.country
					if (pos.accuracy < 50) {
						loc.street_address = res.address.road ? res.address.road : res.display_name
					}
					return loc
				});
			} else {
				console.log('Geocoder failed due to: ' + response.statusText)
				return loc;
			}
		})
	}

	getDistanceFromLatLonInM(lat1: number, lon1: number, lat2: number, lon2: number) {
		const deg2rad = (deg: number) => {
			return deg * (Math.PI / 180)
		};
		const R       = 6371000; // Radius of the earth in m
		const dLat    = deg2rad(lat2 - lat1);
		const dLon    = deg2rad(lon2 - lon1);
		const a       = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
			Math.sin(dLon / 2) * Math.sin(dLon / 2);
		const c       = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		return R * c; // Distance in m
	}

	// Look for the next time starting from now on when the night Sky is visible
	// i.e. when sun is more than 10 degree below horizon.
	// If no such time was found (e.g. in a northern country in summer),
	// we default to current time.
	setTimeAfterSunset() {
		let afterSunset;
		const sun = this.$stel.getObj('NAME Sun');
		const obs = this.$stel.core.observer.clone();
		const utc = Math.floor(obs.utc * 24 * 60 / 5) / (24 * 60 / 5);
		let i;
		for (i = 0; i < 24 * 60 / 5 + 1; i++) {
			obs.utc        = utc + 1.0 / (24 * 60) * (i * 5);
			const sunRadec = sun.getInfo('RADEC', obs);
			const azalt    = this.$stel.convertFrame(obs, 'ICRF', 'OBSERVED', sunRadec);
			const alt      = this.$stel.anpm(this.$stel.c2s(azalt)[1]);
			if (alt < -13 * Math.PI / 180) {
				break;
			}
		}
		if (i === 0 || i === 24 * 60 / 5 + 1) {
			afterSunset = this.$stel.core.observer.utc;
		} else {
			afterSunset = obs.utc;
		}
		this.$stel.core.observer.utc = afterSunset;
	}

	// Get the list of circumpolar stars in a given magnitude range
	//
	// Arguments:
	//   obs      - An observer.
	//   maxMag   - The maximum magnitude above which objects are discarded.
	//   filter   - a function called for each object returning false if the
	//              object must be filtered out.
	//
	// Return:
	//   An array SweObject. It is the responsibility of the caller to properly
	//   destroy all the objects of the list when they are not needed, by calling
	//   obj.destroy() on each of them.
	//
	// Example code:
	//   // Return all cicumpolar stars between mag -2 and 4
	//   let res = swh.getCircumpolarStars(this.$stel.observer, -2, 4)
	//   // Do something with the stars
	//   console.log(res.length)
	//   // Destroy the objects (don't forget this line!)
	//   res.map(e => e.destroy())
	getCircumpolarStars(obs: { latitude: number; }, minMag: number, maxMag: any) {
		const filter = (obj: { getInfo: (arg0: string, arg1?: { latitude: number; } | undefined) => number; }) => {
			if (obj.getInfo('vmag', obs) <= minMag) {
				return false;
			}
			const posJNOW   = this.$stel.convertFrame(obs, 'ICRF', 'JNOW', obj.getInfo('radec'));
			const radecJNOW = this.$stel.c2s(posJNOW);
			const decJNOW   = this.$stel.anpm(radecJNOW[1]);
			if (obs.latitude >= 0) {
				return decJNOW >= Math.PI / 2 - obs.latitude;
			} else {
				return decJNOW <= -Math.PI / 2 + obs.latitude;
			}
		}
		return this.$stel.core.stars.listObjs(obs, maxMag, filter);
	}

	showCircumpolarMask(obs: { latitude: number; }, show: boolean | undefined) {
		if (show === undefined) {
			show = true;
		}
		if (this.circumpolarMask) {
			this.$skyHintsLayer.remove(this.circumpolarMask);
			this.circumpolarMask = undefined;
		}
		if (show) {
			const diam           = 2.0 * Math.PI - Math.abs(obs.latitude) * 2;
			const shapeParams    = {
				pos: [0, 0, obs.latitude > 0 ? -1 : 1, 0],
				frame: this.$stel.FRAME_JNOW,
				size: [diam, diam],
				color: [0.1, 0.1, 0.1, 0.8],
				border_color: [0.1, 0.1, 0.6, 1]
			};
			this.circumpolarMask = this.$skyHintsLayer.add('circle', shapeParams);
		}
	}
}

export default new SWHelper();
