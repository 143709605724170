import './skySource.scss';
import React from 'react';
import Card from 'react-bootstrap/Card';
import WikiSummary from '../../wikiSummary/wikiSummary';
import SWHelper from '../../../assets/sw_helpers';
import deepEqual from '../../../util/deepEqual';
import {SkyModel} from '../../../models/skymodel.model';
import {Unregistered} from '../../../models/star-package.model';

interface SkySourceProps {
	object: Unregistered;
	title: string;
	otherNames: string[];
	isHidden: boolean;
	stel: any;
}

interface SkySourceState {
	wikipediaData: any,
	timer: any,
	items: any[]
}

class SkySource extends React.Component<SkySourceProps, SkySourceState> {
	constructor(props: SkySourceProps) {
		super(props);
		this.state = {
			wikipediaData: '',
			timer: null,
			items: []
		}
	}

	render() {
		return this.props.object && (
			<Card.Body id="star-body">
				<div className="flex-row d-flex justify-content-between align-items-start mb-2">
					<div className="d-inline-flex" id="image-container-un">
						<Card.Img src={this.icon()} id="card-img"
						          onLoad={() => this.showImg()}
						          onError={() => this.hideCardImg()}/>
					</div>
					<div className={'d-inline-flex flex-column' + (this.props.isHidden ? ' is-hidden' : '')}
					     id="stats">
						{this.props.isHidden ? null : this.state.items.map((i: { key: string; value: any; }) => (
							<div className="w-100 d-flex flex-row stat" key={i.value}>
								<strong className="name" dangerouslySetInnerHTML={{__html: i.key}}/>&nbsp;
								<span className="value d-inline-flex flex-row"
								      dangerouslySetInnerHTML={{__html: i.value}}/>
							</div>))}
					</div>
				</div>
				<Card.Title style={{fontSize: '32px'}} className="mt-4 title">{this.props.title}</Card.Title>
				<Card.Subtitle className="subtitle">{this.props.object?.short_name ? (
					<span>{this.props.object?.short_name}&nbsp;&middot;&nbsp;</span>) : ''}{this.type()}</Card.Subtitle>
				{this.renderContent()}
			</Card.Body>);
	}

	renderContent() {
		return !this.props.isHidden ? (<>
			{this.alsoKnownAs()}
			<div>
				{this.wikipediaSummary() && !this.props.isHidden ? (
					<WikiSummary html={this.wikipediaSummary()}/>) : null}
			</div>
		</>) : null;
	}

	icon(): string {
		return SWHelper.iconForSkySource(this.props.object);
	}

	async componentDidMount() {
		if (this.props.object) {
			await this.onObjectChanged(this.props.object);
		}
	}

	async componentDidUpdate(prevProps: Readonly<SkySourceProps>, prevState: Readonly<any>, snapshot?: any) {
		if (prevProps !== this.props) {
			if (this.selectionHasChanged(prevProps)) {
				await this.onObjectChanged(this.props.object!);
			}
		}
	}

	componentWillUnmount() {
		clearInterval(this.state.timer);
	}

	selectionHasChanged(prevProps: SkySourceProps): boolean {
		return !deepEqual(this.props.object, prevProps.object);
	}

	async onObjectChanged(s: SkyModel): Promise<void> {
		if (!s) {
			if (this.state.timer) {
				clearInterval(this.state.timer);
			}
			this.setState({timer: undefined});
			return;
		}
		this.setState({items: this.computeItems()});
		if (this.state.timer) {
			clearInterval(this.state.timer);
		}
		this.setState({
			timer: setInterval(() => {
				this.setState({items: this.computeItems()});
			}, 1000)
		});
		return SWHelper.getSkySourceSummaryFromWikipedia(s).then(data => {
				this.setState({wikipediaData: data});
			}, reason => {
			}
		);
	}

	type() {
		if (!this.props.object || !this.props.object.types) return 'Unknown';
		let morpho = ''
		if (this.props.object?.model_data && this.props.object?.model_data.morpho) {
			morpho = SWHelper.nameForGalaxyMorpho(this.props.object!.model_data.morpho)
			if (morpho) {
				morpho = morpho + ' '
			}
		}
		return morpho + SWHelper.nameForSkySourceType(this.props.object?.types[0])
	}

	alsoKnownAs() {
		return this.props.otherNames?.length ? (<Card.Text className="card-text">
			<span className="text-white-50 info">Also known as:&nbsp;</span>
			<strong className="text-white info">{this.props.otherNames.slice(0, 4).join(', ')}</strong>
		</Card.Text>) : null;
	}

	wikipediaSummary(): string {
		if (!this.state.wikipediaData) {
			return '';
		}
		const page = this.state.wikipediaData.query.pages[Object.keys(this.state.wikipediaData.query.pages)[0]];
		if (!page || !page.extract) {
			return '';
		}
		const wl = '<b><a style="color: #62d1df;" target="_blank" rel="noopener" href="' + this.wikipediaLink() + '">wikipedia</a></b></i>'
		return page.extract.replace(/<p>/g, '').replace(/<\/p>/g, '') + '<span class="grey--text caption" style="margin-left:auto; margin-right:0;"><i>&nbsp; ' + `more on ${wl}` + '</span>'
	}

	wikipediaLink(): string {
		const page = this.state.wikipediaData.query.pages[Object.keys(this.state.wikipediaData.query.pages)[0]]
		if (!page || !page.extract) return ''
		return 'https://en.wikipedia.org/wiki/' + page.title
	}

	computeItems() {
		const obj = SWHelper.$stel?.core.selection;
		if (!obj) return this.state.items;
		const ret = []
		const addAttr = (key: string, attr: string, format: any) => {
			const v = obj.getInfo(attr)
			if (v && !isNaN(v)) {
				ret.push({
					key: key,
					value: format ? format(v) : v.toString()
				});
			}
		}
		addAttr('Magnitude', 'vmag', SWHelper.formatMagnitude.bind(SWHelper))
		addAttr('Distance', 'distance', SWHelper.formatDistance.bind(SWHelper))
		if (this.props.object?.model_data) {
			if (this.props.object!.model_data.radius) {
				ret.push({
					key: 'Radius',
					value: this.props.object!.model_data.radius.toString() + ' Km'
				});
			}
			if (this.props.object!.model_data.spect_t) {
				ret.push({
					key: 'Spectral Type',
					value: this.props.object!.model_data.spect_t
				});
			}
		}

		const formatInt = function (num: any, padLen: number) {
			const pad = new Array(1 + padLen).join('0');
			return (pad + num).slice(-pad.length);
		}

		const formatRA = function (a: any) {
			const raf = SWHelper.$stel.a2tf(a, 1)
			return `<div class="radecVal">${formatInt(raf.hours, 2)}<span class="radecUnit">h</span>&nbsp;</div><div class="radecVal">${formatInt(raf.minutes, 2)}<span class="radecUnit">m</span></div><div class="radecVal">${formatInt(raf.seconds, 2)}.${raf.fraction}<span class="radecUnit">s</span></div>`
		}
		const formatAz = function (a: any) {
			const raf = SWHelper.$stel.a2af(a, 1)
			return `<div class="radecVal">${formatInt(raf.degrees < 0 ? raf.degrees + 180 : raf.degrees, 3)}<span class="radecUnit">°</span></div><div class="radecVal">${formatInt(raf.arcminutes, 2)}<span class="radecUnit">'</span></div><div class="radecVal">${formatInt(raf.arcseconds, 2)}.${raf.fraction}<span class="radecUnit">"</span></div>`
		}
		const formatDec = function (a: any) {
			const raf = SWHelper.$stel.a2af(a, 1)
			return `<div class="radecVal">${raf.sign}${formatInt(raf.degrees, 2)}<span class="radecUnit">°</span></div><div class="radecVal">${formatInt(raf.arcminutes, 2)}<span class="radecUnit">'</span></div><div class="radecVal">${formatInt(raf.arcseconds, 2)}.${raf.fraction}<span class="radecUnit">"</span></div>`
		}
		const posCIRS = SWHelper.$stel.convertFrame(SWHelper.$stel.core.observer, 'ICRF', 'JNOW', obj.getInfo('radec'))
		const radecCIRS = SWHelper.$stel.c2s(posCIRS)
		const raCIRS = SWHelper.$stel.anp(radecCIRS[0])
		const decCIRS = SWHelper.$stel.anpm(radecCIRS[1])
		ret.push({
			key: 'Ra/Dec',
			value: formatRA(raCIRS) + '&nbsp;&nbsp;' + formatDec(decCIRS)
		});
		const azalt = SWHelper.$stel.c2s(SWHelper.$stel.convertFrame(SWHelper.$stel.core.observer, 'ICRF', 'OBSERVED', obj.getInfo('radec')))
		const az = SWHelper.$stel.anp(azalt[0])
		const alt = SWHelper.$stel.anpm(azalt[1])
		ret.push({
			key: 'Az/Alt',
			value: formatAz(az) + '&nbsp;&nbsp;' + formatDec(alt)
		})
		addAttr('Phase', 'phase', SWHelper.formatPhase);
		const visibility = SWHelper.computeVisibility(obj, this.props);
		ret.push({
			key: 'Visibility',
			value: visibility
		})
		return ret;
	}

	hideCardImg() {
		const img = document.getElementById('card-img');
		if (!img) {
			return;
		}
		img.style.display = 'none';
	}

	showImg() {
		const img = document.getElementById('card-img');
		if (!img) {
			return;
		}
		img.style.display = '';
	}

}

export default SkySource;
