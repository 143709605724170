import React from 'react';
import {connect} from 'react-redux';
import Moment from 'moment-timezone';
import './dPicker.scss';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SWHelper from '../../assets/sw_helpers';
import {GeoLocation, StoreState} from '../../redux/store/storeType';
import Button from 'react-bootstrap/Button';
import {OverlayTrigger} from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';

interface DateProps {
	value: Moment.MomentInput,
	stel: any,
	currentLocation: GeoLocation;
	timeZone: string;
	hideNav: boolean,
}

class Picker extends React.Component<DateProps, any> {
	constructor(props: DateProps) {
		super(props);
		this.state = {
			value: this.convertMomentToDate(props.value as Moment.Moment),
			ref: React.createRef()
		}
	}

	componentDidUpdate(prevProps: Readonly<DateProps>, prevState: Readonly<any>, snapshot?: any) {
		if (this.props.stel && (prevProps.value !== this.props.value)) {
			this.setDateTime((this.props.value as Moment.Moment)?.isValid() ?
				(this.props.value as Moment.Moment).toDate() : SWHelper.getLocalTime().toDate())
		}
	}

	convertMomentToDate(dateTime: Moment.Moment) {
		return dateTime?.isValid() ?
			dateTime.toDate() : SWHelper.getLocalTime().toDate();
	};

	getDate(value: Date): Date {
		// the best way to make sure .toDate() doesn't reset the timezone
		return (this.props.timeZone ? Moment.utc(Moment(value).tz(this.props.timeZone).format('YYYY-MM-DDTHH:mm:ss')).toDate() : value);
	}

	getTime(value: Date) {
		return (this.props.timeZone ? Moment(value).tz(this.props.timeZone) : Moment(value)).format('HH:mm:ss');
	};

	getDateAsString(value: Date): string {
		return (this.props.timeZone ? Moment(value).tz(this.props.timeZone) : Moment(value)).format('YYYY-MM-DD');
	};

	setDateTime(value: Date) {
		this.setState({value});
	}

	setStelTime(value: Date): void {
		const val = this.props.timeZone ?
			Moment.tz(Moment(value).format('YYYY-MM-DD HH:mm:ss'), this.props.timeZone).toDate() :
			value;
		this.setDateTime(val);
		SWHelper.setTime(val);
	};

	setAfterSunset = (event: any) => {
		event?.preventDefault();
		event?.stopPropagation();
		SWHelper.setTimeAfterSunset();
	};

	openCalendar() {
		return this.state.ref.current.setOpen(true);
	}

	render() {
		return (
			<div
				className={'bg-dark text-white shadow-lg d-flex flex-column text-center ' + (this.props.hideNav ? 'hide-date' : '')}
				onClick={() => this.openCalendar()}
				id="date-picker">
				<OverlayTrigger placement="left"
				                delay={{show: 250, hide: 400}}
				                overlay={<Tooltip id="button-tooltip">
					                Set at Night
				                </Tooltip>}>
					<Button variant="secondary" id="night-btn" onClick={this.setAfterSunset}><i
						className="bi-moon-fill"/></Button>
				</OverlayTrigger>
				<span><i className="bi-alarm text-white-50"/></span>
				<ReactDatePicker
					selected={this.getDate(this.state.value)}
					onChange={date => this.setStelTime(date as Date)}
					timeIntervals={5}
					ref={this.state.ref}
					showTimeSelect
					className="text-center text-white font-weight-bold border-0 picker"
					withPortal
					dateFormat="MMMM d, yyyy"
				>
					<Button variant="light"
					        style={{
						        position: 'absolute',
						        bottom: '-60px',
						        right: '0',
						        pointerEvents: 'none'
					        }}>Done</Button>
				</ReactDatePicker>
				<span>{this.getTime(this.state.value)}</span>
			</div>);
	};
}

const mapStatetoProps = (state: StoreState) => {
	return {
		stel: state.stel,
		currentLocation: state.currentLocation,
		timeZone: state.timeZone,
		hideNav: state.hideNav
	};
};
export default connect(mapStatetoProps)(Picker);

