import TargetSearch from '../targetSearch/targetSearch';
import React from 'react';
import {connect} from 'react-redux';
import {StoreState} from '../../redux/store/storeType';
import Navbar from 'react-bootstrap/Navbar';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import './toolbar.scss';

class Toolbar extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

	}

	render() {
		return (
			<Navbar id="toolbar" bg="dark"
			        className={'d-flex justify-content-start align-items-center shadow-lg ' + (this.props.hideNav ? 'hide-nav' : '')}>
				<Navbar.Brand href="/" className="d-inline-flex brand-logo">
					<div className="justify-content-between align-items-center">
						<img className="tbtitle d-none d-md-inline" height="25" id="stellarium-web-toolbar-logo"
						     src="images/logo.png"
						     alt="WSR StarFinder Web Logo"/>
						<img className="tbtitle d-inline d-md-none" height="30" id="stellarium-web-toolbar-logo"
						     src="images/logo-white.png"
						     alt="WSR StarFinder Web Logo"/>
					</div>
				</Navbar.Brand>
				<TargetSearch/>
				<div
					className="justify-content-end align-items-center flex-grow-1 d-none d-md-inline-flex">
					<OverlayTrigger
						placement="left"
						delay={{show: 250, hide: 400}}
						overlay={<Tooltip id="button-tooltip">
							Field of View
						</Tooltip>}>
                        <span className="subheader text-white-50"
                              style={{userSelect: 'none'}}><strong>FOV</strong> {this.getFov()}</span>
					</OverlayTrigger>
				</div>
			</Navbar>);
	}

	getFps(): number {
		return this.props?.stel?.fps?.toFixed(1);
	}

	getFov(): string {
		if (!this.props.stel) {
			return '0°';
		}
		const fov = this.props.stel.fov * 180 / Math.PI;
		return fov.toPrecision(3) + '°';
	}

}

const mapStateToProps = (state: StoreState) => {
	return {
		stel: state.stel,
		searchFocused: state.searchFocused,
		hideNav: state.hideNav
	};
};
export default connect(mapStateToProps)(Toolbar);

