import React from 'react';
import './TwinCard.scss';
import ElevateSpring from '../elevatedSpring';

class TwinCard extends React.Component<{ starName: string, isHidden: boolean, goToTwin: () => Promise<void> }, any> {
	constructor(props: { starName: string, isHidden: boolean, goToTwin: () => Promise<void> }) {
		super(props);
	}

	render() {
		return (
			<ElevateSpring>
				<div id="twin-panel" className={'twin-container star-color-bg' + (this.props.isHidden ? ' is-hidden' : '')}
				     onClick={() => this.props.goToTwin()}>
					<div className="d-flex flex-row align-items-center justify-content-between">
						<div className="d-flex-inline flex-row align-items-center">
							<i style={{fontSize: '20px'}} className="bi-stars text-white d-inline-flex text-shadow"/>
						</div>
						<div className="d-flex-inline flex-row align-items-center"
						     style={{transform: 'translateY(-5px)'}}>
							<span className="text-shadow">Twin&nbsp;&middot;&nbsp;
								<strong>{this.props.starName}</strong></span>
						</div>
						<div className="d-flex-inline" style={{transform: 'translateY(-3px)'}}>
							<i style={{fontSize: '20px'}}
							   className="bi-star-fill white text-shadow"/>
						</div>
					</div>
					{this.props.isHidden ? (<span id="hidden-title">{this.props.starName}</span>) : null}
				</div>
			</ElevateSpring>
		);
	}
}

export default TwinCard;
